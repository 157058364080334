
@import "design/variables";

/*
    Document   : styled338
    Created on : 27 mai 2013, 11:34:51
    Author     : PC1
    Description:
    Purpose of the stylesheet follows.
*/

/*
    TODO customize this sample style
    Syntax recommendation http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html
*/

/*-----------------------------
    VARIABLE DE COULEUR DU THEME
-------------------------------*/
.slogan {
    color:#D4D2D8;
}
#blocContent{background:#383838;}
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {background-color: #383838; border-color: #383838;}.pagination > li > a, .pagination > li > span{color:#383838;}
.pagination.pull-right li.active a{color:#fff !important;}
.icon-chevron-sign-left:before {
    color:#908C9A;
}
h2, .h2{border-bottom:4px solid #908C9A;
    color:#908C9A;
    a,a:hover{
        color:inherit;
    }
}

.icon-chevron-sign-right:before {
    color:#908C9A;
}
#footer p {
  color:black;
}

header{
    background:#fff;
}

.pagination li span {
    border: 3px solid transparent;
}
.pagination li span:hover {
    border: 3px solid #ddd;
}

.pagination li.current span, .pagination li:hover span {
    border-color: #ccc;
}

.menu_burger{
    background-color: #fff;
    color: #908C9A;
}

.produits {
    border:1px solid #ddd;
}

nav#nav-principal{
    .navigation_link_p{
        color: #908C9A;
    }
    .navigation_button_p{
        &.actif, &:hover, &.sousmenu_ouvert{
            background:#908C9A;
            .navigation_link_p{
                color: #fff;
            }
            ul{
                border-bottom: 4px solid #908C9A;
                background:#fff;
            }
        }
    }
    .sous_navigation_link_p{
        color:#908C9A;
    }
    .sous_navigation_button_p{
        border-color:#908C9A;
        &.actif, &:hover, &.sousmenu_ouvert{
            background: #908C9A;
            > .sous_navigation_link_p{
                color:#fff;
            }
        }
    }
}
@media #{$medium-and-down}{
    nav#nav-principal{
        .navigation_button_p {
            background-color: #fff;
        }
    }
}

.produits .produit_etiquette:after {
    border-color:#908C9A rgba(65, 129, 217, 0) rgba(65, 129, 217, 0);
  
}
.produits .produit_etiquette {
    background:#908C9A;
}
.produits .produit_etiquette2 {
        background:#908C9A;
 
}
.produits .produit_etiquette2:after {
  border-color: rgba(0, 0, 0, 0)rgb(109, 170, 191)rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
 
}
.lien-mask {
    background: #000;
    background-color:#333;
}

.background-adr {
    border-bottom: 5px solid #908C9A;
    border-top: 5px solid #908C9A;
}

.ie7 .remise-produit:after, .ie8 .remise:after{
    background:#908C9A;
}

.ie7 .lien-mask:hover, .ie8 .lien-mask:hover{
    background: #000;
    background-color:#333;
}

 .ie8 .lien-mask{
    display:none;
}

.ie7 .lien-mask{
    background:#908C9A;
}



.background-adr {
    background: none repeat scroll 0 0 #E37B00;
}

div.iview-items {
    background:rgba(0,0,0,0.3);
    
}


.produits .desc {
    color:#555;

}

.mask > h3,
.mask > .h3 {
    color:#fff;
}



.mask .addcommentaires{
    color:#fff;
}
.content #fiche-produit .wrap-details h4,
.content #fiche-produit .wrap-details .h4{
    color: rgb(85, 85, 85);
}
.content #fiche-produit .remise-produit{
    background:none;
}

.ie7 .produits .remise{
    color:#fff;
    font-size:13px;
}

.ie7 .iview-controlNav .iview-items ul li{
    min-width: 0;
    display:table;
}
.inmap .adresse a,.inmap .adresse,.inmap .adresse p{
    color:#fff;
}

.mention {
    color:#222;
}

.mention a{
    color:#222;
}
.iview-caption a{
    color:#fff;
    
}

.button, .produits .addbasket, .addmsglo {
    color:#fff;
}

header{
   
}


nav.secondaire ul li a{
    color:#555;
}

.content{
    
}

body, .modal-content {
    color:#555;
    background:none repeat scroll 0 0 rgb(234, 234, 234);
     background-image: url("/images/imgd668series/shley-tree-1.png");
}

#contentgalerie p{
    color:#555;
}

.produits .prixprod {
    color:#555;
}

.produits .oldprix {
    color: #555;
}

.details{
    color:#555;
}

.fil-dariane a{
    color:#555;
}

.fil-dariane:hover{
    color:#555;
}

.description{
    color:#555;
}

.content #fiche-produit .wrap-description .prix .oldprix {
    color:#555;
}

.form label {
    /*color:#555;*/
}

.footer-color  { 
   background:white;
}

.iview-controlNav div.iview-items ul li { 
    background:#555;
}

.contact_text {
    color:#555;
}

/* COULEUR 2 */

 .sous_navigation_link_p a  {
  color: white;
}

.title_section, h2, .h2 {
    color:  #908C9A;

}
.content .page, .album-photo, .boutique  {
    border-top-color:#908C9A;
    border-bottom-color:#908C9A;
}
.page a{
    color:#908C9A;
}

.photo {
    border-bottom-color:#908C9A;
}

.mask > .commentaire {
    border-bottom-color: #908C9A;
}

.message_lo {
    border-bottom-color:#908C9A;
}

.message_lo .web a {
    color:#908C9A;
}

.message_lo .note {
    border-color: #908C9A;
    color:#908C9A;
}

.produits .nomprod {
    color:#908C9A;
}

.produits .remise {
    color:#fff;
}

.produits select {
    border-color:#908C9A;
}

.quantite{
    border-color:#908C9A;
}

.declinaison select{
    border-color:#908C9A;
}

.description{
    border-bottom-color:#908C9A;
}

.prixprod{
    color:#908C9A;
    
}

.wrap-description > h3,
.wrap-description > .h3{
    color:#908C9A;
}

.content #fiche-produit .remise-produit{
    color:#fff;
}

.form fieldset textarea {
    background: none repeat scroll 0 0 #DDDDDD;
}

.userdigit .button {
    background-color: #555555;
    position: relative;
    right: -35px;
    top: 1px;
}
.form input {
    background: none repeat scroll 0 0 #DDDDDD;
}
.form input[type=submit]{
    color:#222;
}
.form input[type=submit]:hover{
    color:#fff;
}

#GoogleMapAffichage {

}

.content,
.apercu-article h2.titre-article, .apercu-article .h2.titre-article,
.single-article .titre-article h1, .single-article .titre-article .h1{
    background: #fff;
}

#blocContent, header{
    

}

a.iview-control.active {
    background:#908C9A;
}

.iview-controlNav div.iview-items ul li:hover, .iview-controlNav div.iview-items ul li:active{
    background:#908C9A;
}


.produits .addbasket, .addmsglo, .button {
    background: none repeat scroll 0 0 #555555;
    border-color: #555555;
}

.produits .addbasket:hover, .addmsglo:hover, .button:hover{
    background:#333;
    border-color: #333;
}



.map{

}



.contact_text {
    border-color: #908C9A;
}



.background-adr {
    background: none repeat scroll 0 0 #000000;
}

.iview-caption {
    background: #fff;
}
.iview-caption {
    color: #555;
}

.iview-caption.blackcaption{
    background:#908C9A;
}

.triangle{
    border-left-color:#fff;
    
}

.diaporama:hover .triangle{
    border-left-color:#908C9A;
}

.content{
}


.remise-produit::before, .remise:after {
    border-left: 40px solid #908C9A;
    border-bottom: 40px solid #908C9A;
    border-top: 40px solid transparent;
    border-right: 40px solid transparent;
}
.produit_etiquette,.produit_etiquette2,.produit_etiquette3{
    background:#908C9A;
}
.produit_etiquette::after,.produit_etiquette2::after,.produit_etiquette3::after{
    border-top-color:#908C9A;
}
.produit_etiquette h3,.produit_etiquette2 h3,.produit_etiquette3 h3, .produit_etiquette .h3,.produit_etiquette2 .h3,.produit_etiquette3 .h3{
    color:#fff;
}



nav.nav-static-breadcrumb ul li a{
     background: none repeat scroll 0 0 #ddd;
}
nav.nav-static-breadcrumb ul li:nth-child(2) a:before {
    border-left-color: #fff;
}
nav.nav-static-breadcrumb ul li:first-child a:after {
    border-left-color: #ddd;
}
nav.nav-static-breadcrumb ul li a{
    color: #000;
}

.secondaire ul li:hover > ul {
    background: none repeat scroll 0 0 #FFFFFF;
}
nav.secondaire ul li:hover {
    background: #D4D2D8;
}

.secondaire ul li:hover > ul {   
    border: 1px solid #CCCCCC;
}

.list-photos .galerie .mask,  .produits:hover .zoom{
    background-color:#908c9a;
}

/* Template Panier */

$panier-background-color:#383838;
$panier-titre-color:#fff;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

.template-panier{
    background-color:$panier-background-color;
    color:$panier-titre-color;
    .connexion p{
        border-color:$panier-titre-color;
        &, a{
            color:$panier-titre-color;
        }
    }
    #header-panier{
        color:$panier-titre-color;
    }
    #panier #paniercontent{
        background-color:$panier-content-background-color;
        &, #paniertable{
            color:$panier-texte-color;
        }
        border-color:$panier-background-color;
    }
    &.template-panier-1{
        #panier #paniercontent{
            &, #paniertable{
                color:$panier-titre-color;
            }
        }
    }
    .count-live{
        background:$panier-content-background-color;
        color:$panier-texte-color;
    }
}

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: #908C9A;
    border-color: #908C9A;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: #908C9A;
    color: #908C9A;

    a, span {
        color: #908C9A;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}